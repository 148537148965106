import { Splide } from '@splidejs/splide';

class FeaturedStoriesSlider extends HTMLElement {
  constructor() {
    super();

    this.slides = this.innerHTML;
    this.carousel = new Splide(this, {
      type: 'loop',
      arrows: true,
      mediaQuery: 'min',
      gap: '0.625rem',
      snap: true,
      pagination: false,
      padding: '0',
      easingFunc: 'cubic-bezier(0.25, 1, 0.5, 1)',
      arrowPath: 'M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z',
      classes: {
        arrows: 'splide__arrows ',
        arrow: 'splide__arrow splide__arrow--lg !bg-white',
        prev: '-left-0 splide__arrow--prev !border-l-0',
        next: '-right-0 splide__arrow--next !border-r-0'
      },
      breakpoints: {
        1024: {
          padding: '10%'
        }
      }
    });
  }

  connectedCallback() {
    this.generateCarousel();
  }

  generateCarousel() {
    this.innerHTML = '';
    const list = document.createElement('div');
    const track = document.createElement('div');
    list.classList.add('splide__list', '!flex');
    track.classList.add('splide__track');
    list.innerHTML = this.slides;
    track.append(list);
    this.append(track);
    this.carousel.mount();
  }
}

if (!window.customElements.get('featured-stories')) {
  window.customElements.define('featured-stories', FeaturedStoriesSlider);
}
